import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import Form from './calculateCahpsForm';
import Hero from './calculateCahpsHero';

/**
 * CalculateCAHPS page Main component.
 * @component
 *
 * @returns {component} - <Main />
 */

const Main = () => {
  const {
    contentfulCahpsMarketReport: {
      background,
      backgroundOpacity,
      backgroundSize,
      header,
      subtext: {
        childMarkdownRemark: { html: subtextHtml },
      },
      inputBtn,
      inputEmailPlaceholder,
      inputContractIdPlaceholder,
      successMessage: {
        childMarkdownRemark: { html: successHtml },
      },
    },
  } = useStaticQuery(graphql`
    query CalculateCahps {
      contentfulCahpsMarketReport {
        header
        subtext {
          childMarkdownRemark {
            html
          }
        }
        inputEmailPlaceholder
        inputContractIdPlaceholder
        inputBtn
        background {
          fluid(quality: 95, maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        backgroundSize
        backgroundOpacity
        successMessage {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `);
  const bgStyles = {
    backgroundImage: `url('${background.fluid.src}')`,
    backgroundSize: `${backgroundSize}px ${backgroundSize}px`,
    opacity: backgroundOpacity,
  };

  return (
    <section
      className={cx(base.flex, base.itemsCenter, base.justifyCenter, base.w100)}
      style={{ height: '100vh' }}
    >
      <div className={cx(main.contactMainBgImage)} style={bgStyles} />
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyCenter,
          base.w100,
          main.contactInner,
          main.contactInnerShowcase
        )}
        style={{ minHeight: '100%' }}
      >
        <Hero title={header} body={subtextHtml} />
        <Form
          emailPlaceholder={inputEmailPlaceholder}
          contractIdPlaceholder={inputContractIdPlaceholder}
          successMessage={successHtml}
          btn={inputBtn}
        />
      </div>
    </section>
  );
};

export default Main;
