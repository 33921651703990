import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import CleanHTML from '../cleanHTML';

const titleWidth = {
  width: '100vw',
};

/**
 * CalculateCAHPS page Hero component.
 * @component
 *
 * @returns {component} - <Hero title={string} body={string} />
 */

const Hero = ({ title, body }) => (
  <div
    className={cx(
      base.flex,
      base.flexColumn,
      base.itemsCenter,
      base.justifyBetween,
      base.mb3,
      base.w100,
      main.memberWrap,
      main.memberWrapShow
    )}
  >
    <h2
      className={cx(
        base.mb3,
        main.memberTitle,
        main.heading,
        main.headingShowcase
      )}
      style={titleWidth}
    >
      <CleanHTML html={title} />
    </h2>
    <CleanHTML html={body} />
  </div>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default Hero;
