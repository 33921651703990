import React from 'react';
import PropTypes from 'prop-types';
import { initialize } from 'react-ga';

import Layout from '../components/layout';
import Main from '../components/calculate-cahps/calculateCahpsMainContent';

/**
 * CalculateCAHPS page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <CalculateCAHPS location={string} />
 */

const CalculateCAHPS = ({ location }) => {
  initialize('UA-33936956-01', {
    debug: true,
    titleCase: false,
  });

  return (
    <Layout location={location} showcase>
      <Main />
    </Layout>
  );
};

CalculateCAHPS.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default CalculateCAHPS;
