import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import validator from 'validator';
import { NetlifyForm, Honeypot } from 'react-netlify-forms';
import { event } from 'react-ga';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CleanHTML from '../cleanHTML';

/**
 * CalculateCAHPS page Form component.
 * @component
 *
 * @returns {component} - <Form emailPlaceholder={string} btn={string} />
 */

const Form = ({
  emailPlaceholder,
  btn,
  contractIdPlaceholder,
  successMessage,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [formName, setFormName] = useState('CAHPS Market Report');
  const [sent, setSent] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidContractId, setInvalidContractId] = useState(false);

  const validateEmail = evt => {
    const email = evt.target.value;

    if (email === '' || validator.isEmail(email)) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
  };

  const validateContractId = evt => {
    const contractId = evt.target.value?.trim();

    if (contractId === '' || contractId.length >= 5) {
      setInvalidContractId(false);
    } else {
      setInvalidContractId(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSent(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (sent) {
      event({
        category: 'Form',
        action: 'Submission',
        label: formName,
      });
    }
  }, [sent]);

  const isSubmitDisabled = submitting =>
    submitting || sent || invalidEmail || invalidContractId;

  return (
    <div
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100
      )}
    >
      <NetlifyForm
        name={formName}
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyStart,
          base.w100,
          main.contactFormShowcase
        )}
        honeypotName="bot-field"
      >
        {({ handleChange, success, submitting }) => {
          if (success) {
            setSent(true);
          }

          return sent ? (
            <div
              className={cx(
                base.flex,
                base.flexColumn,
                base.itemsCenter,
                base.justifyCenter,
                base.w100,
                main.contactFormWrap
              )}
            >
              <p
                data-testid="confirmation"
                className={cx(
                  base.ma0,
                  base.tc,
                  base.w100,
                  main.contactFormConfirmation,
                  theme.colorTextGrey,
                  theme.title2
                )}
              >
                <CleanHTML html={successMessage} />
              </p>
            </div>
          ) : (
            <div
              className={cx(
                base.flex,
                base.flexColumn,
                base.itemsCenter,
                base.relative,
                base.w100,
                main.contactFormInnerShow
              )}
              style={{ gap: '20px', maxWidth: '380px' }}
            >
              <Honeypot />
              <div
                className={cx(
                  base.flex,
                  base.flexColumn,
                  base.itemsStart,
                  base.justifyStart,
                  base.relative,
                  base.w100,
                  main.contactFormInnerShow
                )}
                style={{ maxWidth: '100%' }}
              >
                <label
                  htmlFor="email"
                  className={cx(
                    base.mb1,
                    main.srh,
                    theme.colorTextGrey,
                    theme.title4
                  )}
                >
                  Email<span className={theme.colorDanger}> *</span>
                </label>
                <input
                  id="email"
                  data-testid="email"
                  className={cx(
                    base.w100,
                    main.formInputField,
                    main.formInputFieldTall
                  )}
                  style={{ borderRadius: '4px', maxWidth: '100%' }}
                  type="text"
                  name="email"
                  inputMode="email"
                  required
                  placeholder={emailPlaceholder}
                  onChange={evt => {
                    handleChange(evt);
                    validateEmail(evt);
                  }}
                  data-valid={`${!invalidEmail}`}
                />
                <span
                  className={cx(
                    main.emailValidationError,
                    main.emailValidationErrorTall
                  )}
                  aria-hidden={!invalidEmail}
                  style={{ display: invalidEmail ? 'block' : 'none' }}
                >
                  Invalid Email
                </span>
              </div>
              <div
                className={cx(
                  base.flex,
                  base.flexColumn,
                  base.itemsStart,
                  base.justifyStart,
                  base.relative,
                  base.w100,
                  main.contactFormInnerShow
                )}
                style={{ maxWidth: '380px' }}
              >
                <label
                  htmlFor="contractId"
                  className={cx(
                    base.mb1,
                    main.srh,
                    theme.colorTextGrey,
                    theme.title4
                  )}
                >
                  Contract ID<span className={theme.colorDanger}> *</span>
                </label>
                <input
                  id="contractId"
                  data-testid="contractId"
                  className={cx(
                    base.w100,
                    main.formInputField,
                    main.formInputFieldTall
                  )}
                  style={{ borderRadius: '4px', maxWidth: '100%' }}
                  type="text"
                  name="contractId"
                  inputMode="contractId"
                  required
                  placeholder={contractIdPlaceholder}
                  onChange={evt => {
                    handleChange(evt);
                    validateContractId(evt);
                  }}
                  data-valid={`${!invalidContractId}`}
                />
                <span
                  className={cx(
                    main.emailValidationError,
                    main.emailValidationErrorTall
                  )}
                  aria-hidden={!invalidContractId}
                  style={{ display: invalidContractId ? 'block' : 'none' }}
                >
                  Invalid Contract ID
                </span>
              </div>

              <button
                type="submit"
                data-testid="submit"
                className={cx(
                  base.w100,
                  main.button,
                  main.contactButton,
                  main.contactButtonSm,
                  theme.colorWhite,
                  theme.dpBtnBlue
                )}
                disabled={isSubmitDisabled(submitting)}
              >
                {btn}
              </button>
            </div>
          );
        }}
      </NetlifyForm>
    </div>
  );
};

Form.propTypes = {
  emailPlaceholder: PropTypes.string.isRequired,
  contractIdPlaceholder: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  btn: PropTypes.string.isRequired,
};

export default Form;
